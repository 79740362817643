<template>
<main v-if="summaryData !== null && questionnaireData !== null">

  <div class="container-fluid wrapper-medium pt-5 pt-lg-0">
    <div class="row mb-3">
      <div class="col-12 text-center">
        <h1 class="anim-load1">
          TAYC-R results
        </h1>
        <div>
          <h3 class="mt-3">{{questionnaireData.parentFirstName}}, thank you for completing your TAYC-R questionnaire for {{questionnaireData.childFirstName}}.</h3>
        </div>
        <p>This TAYC-R Questionnaire was created on: {{ $date(questionnaireData.sentOn).format('DD MMMM YYYY') }}</p>
      </div>
    </div>
  </div>

  <Footer />
</main>

</template>

<script>
import {surveyFields} from '@/surveyFields'

export default {
  name: 'SurveyTaycrResult',
  components: {  },
  data () {
    return {
      id: null,
      profile: null,
      taycrQuestions: [],
      status: null,
      questionnaireData: null,
      summaryData: null,
      parentJustFilledOutForm: false,
    }
  },
  computed: {
    formComp () {
      return this.$refs.form;
    }
  },
  async created() {
    this.$store.state.loadingProgress = false
    this.$store.state.appSection = 'survey'
    this.id = this.$route.query.id || null
    this.profile = this.$route.query.profile || null
    if (this.id !== null && this.profile !== null) {
      await this.getQuestionnaireStatusGet()
      this.taycrQuestions = surveyFields.taycrQuestions;
      //if parent has just filled out TAYCR survey, they shouldn't be able to see it.
      
    } else {
      // Block access to this page if id and profile not present in url
      this.$router.push('/')
    }
  },

  methods: {
    async getQuestionnaireStatusGet() {
      return this.$http({
        method: 'get', 
        url: this.$api.QuestionnaireStatusGet,
        params: {
          profileId: this.profile,
          questionnaireId: this.id
        }
      })
        .then(async (response) => {
          console.log('getQuestionnaireResults', response.data)
          this.status = response.data
          // If invalid or error, go to homepage. Otherwise let data build the layout
          if (this.status != 2) {
            this.alertMsgShow("Invalid questionnaire", false)
            this.$router.push('/')
          } else {
            await Promise.all([this.getSummary(), this.getQuestionnaireData()]);
          }
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Invalid questionnaire", false)
          this.$router.push('/')
        })
    },

    getQuestionnaireData () {
      return this.$http({
        method: 'get', 
        url: this.$api.QuestionnaireGet,
        params: {
          id: this.id,
          profileId: this.profile
        }
      })
        .then((response) => {
          console.log('getQuestionnaire', response.data)
          this.questionnaireData = response.data
          console.log("questionnaireData call is ",this.questionnaireData);

        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Can't read questionnaire results", false)
        })
        .finally(() => this.$store.state.loadingProgress = false)
    },

    getSummary () {
      return this.$http({
        method: 'get', 
        url: this.$api.QuestionnaireSummaryGet,
        params: {
          profileId: this.profile,
          questionnaireId: this.id
        }
      })
        .then((response) => {
          this.summaryData = response.data
          console.log("summaryData call is ",this.summaryData);
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Can't read questionnaire results", false)
        })
    }

  },
  watch: {
  }
}
</script>

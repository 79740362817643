var surveyFields = {
  
  childCountOptions: [
    { value: 0, text: '0' },
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 6, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' },
    { value: 9, text: '9' },
    { value: 10, text: '10' }
  ],

  organisationApprovedOptions: [
    { value: "Regional Adoption Agency" , text: "Regional Adoption Agency" },
    { value: "Local Authority" , text: "Local Authority" },
    { value: "Voluntary Adoption Agency" , text: "Voluntary Adoption Agency" }
  ],

  organisationRegionalOptions: [
    { value: "Adopt East" , text: "Adopt East" },
    { value: "Adopt London RAA" , text: "Adopt London RAA" },
    { value: "Adopt North East" , text: "Adopt North East" },
    { value: "Adopt South (AS)" , text: "Adopt South (AS)" },
    { value: "Adopt South West" , text: "Adopt South West" },
    { value: "Adopt Tees Valley" , text: "Adopt Tees Valley" },
    { value: "Adopt Thames Valley" , text: "Adopt Thames Valley" },
    { value: "Adoption Central England (ACE)" , text: "Adoption Central England (ACE)" },
    { value: "Adoption Connects" , text: "Adoption Connects" },
    { value: "Adoption Counts" , text: "Adoption Counts" },
    { value: "Adoption in Merseyside" , text: "Adoption in Merseyside" },
    { value: "Adoption Now" , text: "Adoption Now" },
    { value: "Adoption South East" , text: "Adoption South East" },
    { value: "Adoption West" , text: "Adoption West" },
    { value: "Adoption@Heart" , text: "Adoption@Heart" },
    { value: "Ambitious for Adoption (Coram)" , text: "Ambitious for Adoption (Coram)" },
    { value: "Aspire Adoption" , text: "Aspire Adoption" },
    { value: "Cambridgeshire and Peterborough" , text: "Cambridgeshire and Peterborough" },
    { value: "Coast to Coast" , text: "Coast to Coast" },
    { value: "D2N2" , text: "D2N2" },
    { value: "Kent, Medway, Bexley" , text: "Kent, Medway, Bexley" },
    { value: "L3R" , text: "L3R" },
    { value: "Lancashire and Blackpool" , text: "Lancashire and Blackpool" },
    { value: "LAs not in an RAA (yet)" , text: "LAs not in an RAA (yet)" },
    { value: "One Adoption" , text: "One Adoption" },
    { value: "Together for Adoption" , text: "Together for Adoption" },
    { value: "Together4Children" , text: "Together4Children" },
    { value: "Other" , text: "Other" }
  ],

  organisationLocalOptions: [
    { value: "Barking and Dagenham", text: "Barking and Dagenham" },
    { value: "Barnet", text: "Barnet" },
    { value: "Barnsley", text: "Barnsley" },
    { value: "Bath and North East Somerset", text: "Bath and North East Somerset" },
    { value: "Bedford Borough", text: "Bedford Borough" },
    { value: "Bexley", text: "Bexley" },
    { value: "Birmingham", text: "Birmingham" },
    { value: "Blackburn with Darwen", text: "Blackburn with Darwen" },
    { value: "Blackpool", text: "Blackpool" },
    { value: "Bolton", text: "Bolton" },
    { value: "Bournemouth", text: "Bournemouth" },
    { value: "Bracknell Forest", text: "Bracknell Forest" },
    { value: "Bradford", text: "Bradford" },
    { value: "Brent", text: "Brent" },
    { value: "Brighton and Hove", text: "Brighton and Hove" },
    { value: "Bristol, City of", text: "Bristol, City of" },
    { value: "Bromley", text: "Bromley" },
    { value: "Buckinghamshire", text: "Buckinghamshire" },
    { value: "Bury", text: "Bury" },
    { value: "Calderdale", text: "Calderdale" },
    { value: "Cambridgeshire", text: "Cambridgeshire" },
    { value: "Camden", text: "Camden" },
    { value: "Central Bedfordshire", text: "Central Bedfordshire" },
    { value: "Cheshire East", text: "Cheshire East" },
    { value: "Cheshire West and Chester", text: "Cheshire West and Chester" },
    { value: "City Of London", text: "City Of London" },
    { value: "Cornwall", text: "Cornwall" },
    { value: "Coventry", text: "Coventry" },
    { value: "Croydon", text: "Croydon" },
    { value: "Cumbria", text: "Cumbria" },
    { value: "Darlington", text: "Darlington" },
    { value: "Derby", text: "Derby" },
    { value: "Derbyshire", text: "Derbyshire" },
    { value: "Devon", text: "Devon" },
    { value: "Doncaster", text: "Doncaster" },
    { value: "Dorset", text: "Dorset" },
    { value: "Dudley", text: "Dudley" },
    { value: "Durham", text: "Durham" },
    { value: "Ealing", text: "Ealing" },
    { value: "East Riding of Yorkshire", text: "East Riding of Yorkshire" },
    { value: "East Sussex", text: "East Sussex" },
    { value: "Enfield", text: "Enfield" },
    { value: "Essex", text: "Essex" },
    { value: "Forest", text: "Forest" },
    { value: "Gateshead", text: "Gateshead" },
    { value: "Gloucestershire", text: "Gloucestershire" },
    { value: "Greenwich", text: "Greenwich" },
    { value: "Hackney", text: "Hackney" },
    { value: "Halton", text: "Halton" },
    { value: "HamletsC", text: "HamletsC" },
    { value: "Hammersmith and Fulham", text: "Hammersmith and Fulham" },
    { value: "Hampshire", text: "Hampshire" },
    { value: "Haringey", text: "Haringey" },
    { value: "Harrow", text: "Harrow" },
    { value: "Hartlepool", text: "Hartlepool" },
    { value: "Havering", text: "Havering" },
    { value: "Herefordshire", text: "Herefordshire" },
    { value: "Hertfordshire", text: "Hertfordshire" },
    { value: "Hillingdon", text: "Hillingdon" },
    { value: "Hounslow", text: "Hounslow" },
    { value: "Isle Of Wight", text: "Isle Of Wight" },
    { value: "Isles Of Scilly", text: "Isles Of Scilly" },
    { value: "Islington", text: "Islington" },
    { value: "Kensington and Chelsea", text: "Kensington and Chelsea" },
    { value: "Kent", text: "Kent" },
    { value: "Kingston Upon Hull, City of", text: "Kingston Upon Hull, City of" },
    { value: "Kingston Upon Thames", text: "Kingston Upon Thames" },
    { value: "Kirklees", text: "Kirklees" },
    { value: "Knowsley", text: "Knowsley" },
    { value: "Lambeth", text: "Lambeth" },
    { value: "Lancashire", text: "Lancashire" },
    { value: "Leeds", text: "Leeds" },
    { value: "Leicester", text: "Leicester" },
    { value: "Leicestershire", text: "Leicestershire" },
    { value: "Lewisham", text: "Lewisham" },
    { value: "Lincolnshire", text: "Lincolnshire" },
    { value: "Liverpool", text: "Liverpool" },
    { value: "Luton", text: "Luton" },
    { value: "Manchester", text: "Manchester" },
    { value: "Medway Towns", text: "Medway Towns" },
    { value: "Merton", text: "Merton" },
    { value: "Middlesbrough", text: "Middlesbrough" },
    { value: "Milton Keynes", text: "Milton Keynes" },
    { value: "Newcastle Upon Tyne", text: "Newcastle Upon Tyne" },
    { value: "Newham", text: "Newham" },
    { value: "Norfolk", text: "Norfolk" },
    { value: "North East Lincolnshire", text: "North East Lincolnshire" },
    { value: "North Lincolnshire", text: "North Lincolnshire" },
    { value: "North Somerset", text: "North Somerset" },
    { value: "North Tyneside", text: "North Tyneside" },
    { value: "North Yorkshire", text: "North Yorkshire" },
    { value: "Northamptonshire", text: "Northamptonshire" },
    { value: "Northumberland", text: "Northumberland" },
    { value: "Nottingham", text: "Nottingham" },
    { value: "Nottinghamshire", text: "Nottinghamshire" },
    { value: "Oldham", text: "Oldham" },
    { value: "Oxfordshire", text: "Oxfordshire" },
    { value: "Peterborough", text: "Peterborough" },
    { value: "Plymouth", text: "Plymouth" },
    { value: "Poole", text: "Poole" },
    { value: "Portsmouth", text: "Portsmouth" },
    { value: "Reading", text: "Reading" },
    { value: "Redbridge", text: "Redbridge" },
    { value: "Redcar and Cleveland", text: "Redcar and Cleveland" },
    { value: "Richmond Upon Thames", text: "Richmond Upon Thames" },
    { value: "Rochdale", text: "Rochdale" },
    { value: "Rotherham", text: "Rotherham" },
    { value: "Rutland", text: "Rutland" },
    { value: "Salford", text: "Salford" },
    { value: "Sandwell", text: "Sandwell" },
    { value: "Sefton", text: "Sefton" },
    { value: "Sheffield", text: "Sheffield" },
    { value: "Shropshire", text: "Shropshire" },
    { value: "Slough", text: "Slough" },
    { value: "Solihull", text: "Solihull" },
    { value: "Somerset", text: "Somerset" },
    { value: "South Gloucestershire", text: "South Gloucestershire" },
    { value: "South Tyneside", text: "South Tyneside" },
    { value: "Southampton", text: "Southampton" },
    { value: "Southend-on-Sea", text: "Southend-on-Sea" },
    { value: "Southwark", text: "Southwark" },
    { value: "St Helens", text: "St Helens" },
    { value: "Staffordshire", text: "Staffordshire" },
    { value: "Stockport", text: "Stockport" },
    { value: "Stockton-On-Tees", text: "Stockton-On-Tees" },
    { value: "Stoke-On-Trent", text: "Stoke-On-Trent" },
    { value: "Suffolk", text: "Suffolk" },
    { value: "Sunderland", text: "Sunderland" },
    { value: "Surrey", text: "Surrey" },
    { value: "Sutton", text: "Sutton" },
    { value: "Swindon", text: "Swindon" },
    { value: "Tameside", text: "Tameside" },
    { value: "Telford and Wrekin", text: "Telford and Wrekin" },
    { value: "Thurrock", text: "Thurrock" },
    { value: "Torbay", text: "Torbay" },
    { value: "Tower", text: "Tower" },
    { value: "Trafford", text: "Trafford" },
    { value: "Wakefield", text: "Wakefield" },
    { value: "Walsall", text: "Walsall" },
    { value: "Waltham", text: "Waltham" },
    { value: "Wandsworth", text: "Wandsworth" },
    { value: "Warrington", text: "Warrington" },
    { value: "Warwickshire", text: "Warwickshire" },
    { value: "West Berkshire", text: "West Berkshire" },
    { value: "West Sussex", text: "West Sussex" },
    { value: "Westminster", text: "Westminster" },
    { value: "Wigan", text: "Wigan" },
    { value: "Wiltshire", text: "Wiltshire" },
    { value: "Windsor and Maidenhead", text: "Windsor and Maidenhead" },
    { value: "Wirral", text: "Wirral" },
    { value: "Wokingham", text: "Wokingham" },
    { value: "Wolverhampton", text: "Wolverhampton" },
    { value: "Worcestershire", text: "Worcestershire" },
    { value: "York", text: "York" },
    { value: "Other", text: "Other" }
  ],

  organisationVaaOptions: [
    { value: "Action for Children", text: "Action for Children" },
    { value: "Adopters for Adoption", text: "Adopters for Adoption" },
    { value: "Adoption Focus", text: "Adoption Focus" },
    { value: "Adoption Matters", text: "Adoption Matters" },
    { value: "AdoptionPlus", text: "AdoptionPlus" },
    { value: "ARC NE", text: "ARC NE" },
    { value: "Barnardo's", text: "Barnardo's" },
    { value: "Birmingham Childrens Trust", text: "Birmingham Childrens Trust" },
    { value: "Caritas Care", text: "Caritas Care" },
    { value: "CCS Adoption", text: "CCS Adoption" },
    { value: "Coram", text: "Coram" },
    { value: "Diagrama", text: "Diagrama" },
    { value: "Faith in Families", text: "Faith in Families" },
    { value: "Families for Children", text: "Families for Children" },
    { value: "Family Care Society", text: "Family Care Society" },
    { value: "Family Futures CIC", text: "Family Futures CIC" },
    { value: "IAC", text: "IAC" },
    { value: "Nugent Care", text: "Nugent Care" },
    { value: "PACT", text: "PACT" },
    { value: "Scottish Adoption", text: "Scottish Adoption" },
    { value: "SSAFA", text: "SSAFA" },
    { value: "St Andrew's", text: "St Andrew's" },
    { value: "St David's", text: "St David's" },
    { value: "St Francis'", text: "St Francis'" },
    { value: "St Margaret's", text: "St Margaret's" },
    { value: "Yorkshire Adoption Agency", text: "Yorkshire Adoption Agency" },
    { value: "Other", text: "Other" }
  ],

  userGenderOptions: [
    { value: "Male" , text: "Male" },
    { value: "Female" , text: "Female" },
    { value: "Non-binary" , text: "Non-binary" },
    { value: "Transgender" , text: "Transgender" },
    { value: "Other" , text: "Other" },
    { value: "Prefer not to say" , text: "Prefer not to say" },
  ],

  ethnicityOptions: [
    { value: "African", text: "African" },
    { value: "Any other Asian background", text: "Any other Asian background" },
    { value: "Any other black background", text: "Any other black background" },
    { value: "Any other ethnic group", text: "Any other ethnic group" },
    { value: "Any other Mixed/Multiple ethnic background", text: "Any other Mixed/Multiple ethnic background" },
    { value: "Bangladeshi", text: "Bangladeshi" },
    { value: "Caribbean", text: "Caribbean" },
    { value: "Chinese", text: "Chinese" },
    { value: "Gypsy/Roma", text: "Gypsy/Roma" },
    { value: "Traveller of Irish heritage", text: "Traveller of Irish heritage" },
    { value: "Indian", text: "Indian" },
    { value: "White Irish", text: "White Irish" },
    { value: "Pakistani", text: "Pakistani" },
    { value: "White and Asian", text: "White and Asian" },
    { value: "White and Black African", text: "White and Black African" },
    { value: "White and Black Caribbean", text: "White and Black Caribbean" },
    { value: "White British", text: "White British" },
    { value: "Any other white background", text: "Any other white background" },
    { value: "Refused", text: "Refused" },
    { value: "Information not yet obtained", text: "Information not yet obtained" }
  ],

  relationshipOptions: [
    { value: "Single", text: "Single" },
    { value: "Married", text: "Married" },
    { value: "In an unmarried couple", text: "In an unmarried couple" },
    { value: "Divorced", text: "Divorced" },
    { value: "Widowed", text: "Widowed" }    
  ],

  sexualOrientationOptions: [
    { value: "Heterosexual or straight", text: "Heterosexual or straight" },
    { value: "Homosexual", text: "Homosexual" },
    { value: "Prefer not to say", text: "Prefer not to say" }  
  ],

  userDiagnosisOptions: [
    { value: "chronicIllness", text: "Chronic illness" },
    { value: "disability", text: "Disability" },
    { value: "depression", text: "Depression" },
    { value: "anxiety", text: "Anxiety" },
    { value: "other", text: "Other" },
    { value: "none", text: "None" },
  ],

  fosterPlacementsCountOptions: [
    { value: 0, text: '0' },
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 6, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' },
    { value: 9, text: '9' },
    { value: 10, text: '10' }
  ],

  badExperienceOptions: [
    { value: "sexualAbuse", text: "Sexual abuse" },
    { value: "physicalAbuse", text: "Physical abuse" },
    { value: "emotionalAbuse", text: "Emotional abuse" },
    { value: "neglect", text: "Neglect" },
    { value: "domesticViolence", text: "Domestic violence" },
    { value: "inUteroHarm", text: "In utero harm-drugs alcohol" },
    { value: "parentalLearningDisability", text: "Learning disability in birth parent(s)" },
    { value: "other", text: "Other" },
    { value: "none", text: "None" }
  ],

  childDiagnosisOptions: [
    { value: "learningDisability", text: "Learning disability" },
    { value: "autism", text: "Autism" },
    { value: "fasd", text: "Foetal Alcohol Spectrum Disorder (FASD)" },
    { value: "ptsd", text: "Post Traumatic Stress Disorder (PTSD)" },
    { value: "conductDisorder", text: "Conduct disorder or oppositional defiant disorder" },
    { value: "depression", text: "Depression" },
    { value: "attachmentDisorder", text: "Attachment Disorder" },
    { value: "other", text: "Other" },
    { value: "none", text: "None" }
  ],

  supportFamilyOptions: [
    { value: "ddp", text: "Dyadic Developmental Psychotherapy (DDP)" },
    { value: "theraplay", text: "Theraplay" },
    { value: "localAuthoritySocialWork", text: "Local authority social work support" },
    { value: "lifeStoryWork", text: "Therapeutic life story work" },
    { value: "camh", text: "CAMH support" },
    { value: "filialTherapy", text: "Filial therapy" },
    { value: "attachmentTherapy", text: "Attachment therapy" },
    { value: "solutionFocusedTherapy", text: "Solution focused therapy" },
    { value: "videoInteractionGuidance", text: "Video interaction guidance" },
    { value: "dramaTherapy", text: "Drama therapy" },
    { value: "legoTherapy", text: "Lego therapy" },
    { value: "danceMovementTherapy", text: "Dance movement therapy" },
    { value: "sensoryRegulationTherapy", text: "Sensory regulation therapy" },
    { value: "mindfulness", text: "Mindfulness" }, 
    { value: "multiDisciplinaryAssessment", text: "Multi-disciplinary Assessment" },
    { value: "attachmentAssessment", text: "Attachment Assessment" },
    { value: "otherSupportType", text: "Other" }
  ],

  supportChildOptions: [
    { value: "ddp", text: "Dyadic Developmental Psychotherapy (DDP)" },
    { value: "theraplay", text: "Theraplay" },
    { value: "schoolCounselling", text: "School Counselling" },
    { value: "localAuthoritySocialWork", text: "Local authority social work support" },
    { value: "mindfulness", text: "Mindfulness" },
    { value: "emdr", text: "Eye Movements Desensitisation and Reprocessing (EMDR)" },
    { value: "individualChildPsychotherapy", text: "Individual child psychotherapy" },
    { value: "lifeStoryWork", text: "Therapeutic life story work" },
    { value: "camh", text: "CAMH support" },
    { value: "cbt", text: "CBT (Cognitive Behavioural Therapy)" },
    { value: "traumaFocussedCBT", text: "Trauma-focussed CBT" },
    { value: "artTherapy", text: "Art therapy" },
    { value: "playTherapy", text: "Play therapy" },
    { value: "filialTherapy", text: "Filial therapy" },
    { value: "dramaTherapy", text: "Drama therapy" },
    { value: "legoTherapy", text: "Lego therapy" },
    { value: "danceMovementTherapy", text: "Dance movement therapy" },
    { value: "musicTherapy", text: "Music therapy" },
    { value: "sensoryTherapy", text: "Sensory regulation therapy" },
    { value: "solutionFocusedTherapy", text: "Solution focused therapy" },
    { value: "multiDisciplinaryAssessment", text: "Multi-disciplinary assessment" },
    { value: "attachmentAssessment", text: "Attachment assessment" },
    { value: "neuroDevelopmentalAssessments", text: "Neuro-developmental/Neuro-psychological assessment" },
    { value: "cognitiveAssessment", text: "Cognitive assessment" },
    { value: "sensoryAssessment", text: "Sensory assessment" },
    { value: "storyStemAssessment", text: "Story stem assessment" },
    { value: "psychiatricAssessment", text: "Psychiatric assessment" },
    { value: "otherSupportType", text: "Other" }
  ],

  supportPersonalOptions: [
    { value: "therapeuticParentingGroup", text: "Therapeutic parenting group programme" },
    { value: "localAuthoritySocialWork", text: "Local authority social work support" },
    { value: "parentSupportGroup", text: "Parent support group" },
    { value: "peerSupportGroup", text: "Peer support group or buddy scheme" },
    { value: "mindfulness", text: "Mindfulness" },
    { value: "edmr", text: "Eye Movements Desensitisation and Reprocessing (EMDR)" },
    { value: "traumaFocussedCBT", text: "Trauma-focussed CBT" },
    { value: "counsellingForParent", text: "Counselling" },
    { value: "psychotherapy", text: "Psychotherapy" },
    { value: "nonViolenceResistanceTraining", text: "Non violence resistance training" },
    { value: "otherSupportType", text: "Other" }
  ],

  supportPastOptions: [
    { value: "ddp", text: "Dyadic Developmental Psychotherapy (DDP)" },
    { value: "theraplay", text: "Theraplay" },
    { value: "therapeuticParentingGroup", text: "Therapeutic parenting group programme" },
    { value: "localAuthoritySocialWork", text: "Local authority social work support" },
    { value: "parentSupportGroup", text: "Parent support group" },
    { value: "peerSupportGroup", text: "Peer support group or buddy scheme" },
    { value: "mindfulness", text: "Mindfulness" },
    { value: "edmr", text: "Eye Movements Desensitisation and Reprocessing (EMDR)" },
    { value: "individualChildPsychotherapy", text: "Individual child psychotherapy" },
    { value: "lifeStoryWork", text: "Therapeutic Life story work" },
    { value: "camh", text: "CAMH support" },
    { value: "cbt", text: "CBT (Cognitive Behavioural Therapy)" },
    { value: "traumaFocussedCBT", text: "Trauma-focussed CBT" },
    { value: "counsellingForParent", text: "Counselling for parent" },
    { value: "artTherapy", text: "Art therapy" },
    { value: "playTherapy", text: "Play therapy" },
    { value: "filialTherapy", text: "Filial therapy" },
    { value: "schoolCounselling", text: "School Counselling" },
    { value: "sensoryRegulationTherapy", text: "Sensory regulation therapy" },
    { value: "attachmentTherapy", text: "Attachment therapy" },
    { value: "solutionFocusedTherapy", text: "Solution focused therapy" },
    { value: "videoInteractionGuidance", text: "Video interaction guidance" },
    { value: "dramaTherapy", text: "Drama therapy" },
    { value: "legoTherapy", text: "Lego therapy" },
    { value: "danceMovementTherapy", text: "Dance movement therapy" },
    { value: "nonViolenceResistanceTraining", text: "Non violence resistance training" },
    { value: "multiDisciplinaryAssessment", text: "Multi-disciplinary Assessment" },
    { value: "attachmentAssessment", text: "Attachment Assessment" },
    { value: "neuroDevelopmentalAssessments", text: "Neuro-developmental/Neuro-psychological assessment" },
    { value: "cognitiveAssessment", text: "Cognitive assessment" },
    { value: "sensoryAssessment", text: "Sensory assessment" },
    { value: "storyStemAssessment", text: "Story stem assessment" },
    { value: "otherSupportType", text: "Other" }
  ],

  childLiveWithOptions: [
    { value: "Yes", text: "Yes" },
    { value: "No - Boarding school", text: "No - Boarding school" },
    { value: "No - Foster Care", text: "No - Foster Care" },
    { value: "No - Residential Care", text: "No - Residential Care" },
    { value: "No - Other", text: "No - Other" }
  ],

  taycrQuestions: [
    { id: "q1", text: "How much do you feel you understand your child’s difficulties?" },
    { id: "q2", text: "How much do you understand why your child behaves as she or he does?" },
    { id: "q3", text: "How confident are you that you can manage the challenges that your child has?" },
    { id: "q4", text: "How skilled do you feel in managing the specific challenges your child presents?" },
    { id: "q5", text: "How good do you feel your relationship is with your child?" },
    { id: "q6", text: "How easily can you and your child communicate with one another?" },
    { id: "q7", text: "How responsive do you feel that your child is to your attempts to help him/her?" },
    { id: "q8", text: "How easy is your child to care for?" },
    { id: "q9", text: "How easy is it to build a relationship with your child?" },
    { id: "q10", text: "How rewarding do you find your child?" },
    { id: "q11", text: "How secure do you feel the placement/family situation is at the moment?" }
  ]

}

export {
  surveyFields
}